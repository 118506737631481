import React from "react";
import { PageProps } from "gatsby";
import { Helmet } from "gatsby-plugin-react-i18next";
import { Player } from "@lottiefiles/react-lottie-player";

import { AgeWarning } from "components/AgeWarning";
import { WarningContextProvider } from "hooks/useAgeWarningState";
import { DMMPopupContextProvider } from "hooks/useDMMPopupState";
import { useMetadata } from "hooks/useMetadata";
import { SoundWarningContextProvider } from "hooks/useSoundWarning";

export default function MyModal({
    children,
    pageContext,
}: PageProps<null, { layout: string; language: string }>) {
    const metadata = useMetadata();

    if (pageContext.layout === "no-layout") return <>{children}</>;

    return (
        <WarningContextProvider>
            <SoundWarningContextProvider>
                <DMMPopupContextProvider>
                    <Helmet
                        htmlAttributes={{ lang: pageContext.language }}
                        title={metadata?.title ?? ""}
                        defer={false}
                    >
                        <meta
                            name="description"
                            content={metadata?.description ?? ""}
                        />
                        <meta
                            property="og:title"
                            content={metadata?.og?.title ?? ""}
                        />
                        <meta
                            property="og:description"
                            content={metadata?.og?.description ?? ""}
                        />
                        <meta
                            property="og:type"
                            content={metadata?.og?.type ?? ""}
                        />
                        <meta
                            property="og:site_name"
                            content={metadata?.og?.site_name ?? ""}
                        />
                        <meta
                            property="twitter:card"
                            content={metadata?.twitter?.card ?? ""}
                        />
                        <meta
                            property="twitter:title"
                            content={metadata?.twitter?.title ?? ""}
                        />
                        <meta
                            property="twitter:description"
                            content={metadata?.twitter?.description ?? ""}
                        />
                    </Helmet>
                    {children}
                    <Player
                        autoplay
                        loop
                        speed={0.3}
                        src="/confetti.json"
                        className="opacity-60 fixed inset-0 portrait:w-[100vh] landscape:h-[100vw] [&_svg]:transform [&_svg]:!rotate-90 pointer-events-none"
                    />
                    <AgeWarning />
                </DMMPopupContextProvider>
            </SoundWarningContextProvider>
        </WarningContextProvider>
    );
}
